.navigation-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
}

.nav-items {
  display: flex;
  gap: 20px;
}

.navigation-logo {
  width: 48px;
  height: 48px;
}

.nav-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #777;
}

.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mobile-menu-toggle span {
  width: 24px;
  height: 2px;
  background-color: #333;
  margin-bottom: 4px;
  transition: background-color 0.3s;
}

.mobile-menu-toggle.active span {
  background-color: #777;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .nav-items {
    display: none;
  }

  .mobile-menu-toggle {
    display: flex;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-nav .nav-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s;
  }

  .mobile-nav .nav-button:hover {
    background-color: #777;
  }
}
