.about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
 .responsive-image {
    width: auto;
    max-height: 60vh; /* 50% of the viewport height */
    object-fit: contain; /* To maintain aspect ratio */
    float: left;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  p {
    line-height: 1.6;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  li {
    margin-bottom: 5px;
  }
  