.under-construction .responsive-image {
  width: auto;
  max-height: 50vh; /* 50% of the viewport height */
  object-fit: contain; /* To maintain aspect ratio */
  float: left;
}

.under-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
    color: #333;
}

.innerstylediv {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.under-construction h1 { 
    font-size: 2rem; 
    margin-bottom: 1rem; 
}

.under-construction p { 
    font-size: 1.2rem; 
}
